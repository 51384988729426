import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';
export default {
  mixins: [baseCrudMixin],
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  data: () => ({
    entity: 'User',
    returnTo: 'Users'
  }),
  created() {
    const domainID = this.$store.getters.domainID;
    this.$store.dispatch('role/getItems', { domainID: domainID });
  },
  methods: {
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
